<template>

    <main-container icon="mdi-alert" title="Não autorizado">
        <v-container
        class="text-center"
        fill-height
      >
        <v-row align="center">
          <v-col>
            <h1 class="text-h1 red--text">Ops, 500</h1>

            <p class="text-h5 mt-4">Ocorreu um problema!</p>
            <p class="body-1">Para um melhor acompanhamento do problema, envie para <strong>helpdesk@emcsoft.com.br</strong> os passos feitos até receber essa mensagem. Logo entraremos em contato.</p>

            <v-btn
              :to="`/`"
              color="primary"
              outlined
            >
              Voltar para o início!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </main-container>
</template>

<script>
export default {
    name: 'NaoAutorizado'
}
</script>